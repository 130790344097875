<template>
	<div v-loading="pageloading">
		<el-card v-if="!isauthorization">
			<div class="content">
				<div class="left">
					<img src="https://cdn.dkycn.cn/melyshop/image/20210802-b7dc93ee-4c84-4045-9d93-ee3f06fe4b35.png">
					<div class="info">
						<div class="tit">客户管理</div>
						<div class="tip">查询已添加员工企业微信的客户数据，查看客户所在群聊信息，查看客户动态与详情。</div>
					</div>
				</div>
				<div class="right">
					<el-button type="primary" @click="jump">授权安装企店助手</el-button>
				</div>
			</div>
			<div style="margin-top:25px">
				<div class="desc-title">1/企微客户列表</div>
				<img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210802-01d34f25-31cf-4bb4-8c10-927a34afaaef.png"></img>
				<div class="desc-title">2/客户详情面板</div>
				<img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210803-99b94293-eb35-4535-a48b-058a4f93addf.png"></img>
				<div class="desc-title">3/客户标签管理</div>
				<img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210804-ef0aa39d-b6c2-4bfb-b5f1-578bd91c1355.png"></img>
			</div>
		</el-card>
		<div v-else>
			<el-card shadow="never">
				<div class="blue-box" v-loading="clientLoading">
					<div style="text-align:center;margin:20px 0px">
						<div style="font-size:18px;font-weight:bold">{{ clientNum1 }}</div>
						<div style="font-size:14px;color:rgb(180,180,180);margin-top:5px">
							全部客户数
						</div>
					</div>
					<div style="width:1px;height:30px;background:rgba(180,180,180,0.5);margin-top:25px"></div>
					<div style="text-align:center;margin:20px 0px">
						<div style="font-size:18px;font-weight:bold">{{ clientNum2 }}</div>
						<div style="font-size:14px;color:rgb(180,180,180);margin-top:5px">
							重复添加好友客户数
						</div>
					</div>
					<div style="width:1px;height:30px;background:rgba(180,180,180,0.5);margin-top:25px"></div>
					<div style="text-align:center;margin:20px 0px">
						<div style="font-size:18px;font-weight:bold">{{ clientNum3 }}</div>
						<div style="font-size:14px;color:rgb(180,180,180);margin-top:5px">
							今日新增好友关系
						</div>
					</div>
					<div style="width:1px;height:30px;background:rgba(180,180,180,0.5);margin-top:25px"></div>
					<div style="text-align:center;margin:20px 0px">
						<div style="font-size:18px;font-weight:bold">{{ clientNum4 }}</div>
						<div style="font-size:14px;color:rgb(180,180,180);margin-top:5px">
							今日流失好友关系
						</div>
					</div>
					<div style="width:1px;height:30px;background:rgba(180,180,180,0.5);margin-top:25px"></div>
					<div style="text-align:center;margin:20px 0px">
						<div style="font-size:18px;font-weight:bold">{{ clientNum5 }}</div>
						<div style="font-size:14px;color:rgb(180,180,180);margin-top:5px">
							今日净增好友关系
						</div>
					</div>
				</div>
			</el-card>
			<el-card style="margin-top:10px" shadow="never">
				<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
					active-text-color="#409EFF">
					<el-menu-item index="1">全部客户</el-menu-item>
					<el-menu-item index="2">按所在群聊筛选</el-menu-item>
				</el-menu>
				<div style="margin-top:15px" v-show="activeIndex == '1'">
					<div class="FlexRow" style="justify-content: space-between;">
						<div style="display:flex;flex-direction:row;flex-wrap:wrap">
							<div class="FlexRow" style="margin-bottom:10px" >
								<div style="font-size:14px;line-height:37px;color: #606266;font-weight: bold;width: 65px;text-align: right;">关键字:</div>
								<el-select v-model="selValue" placeholder="" style="margin:0px 10px;width:120px"
									@change="selChange">
									<el-option v-for="(v, i) in selOption" :key="i" :value="v.value" :label="v.label">
									</el-option>
								</el-select>
								<el-input v-model="keywords1" :placeholder="placeholder" style="width:250px;margin-right: 10px;"></el-input>
							</div>
							<div class="FlexRow" style="margin-bottom:10px">
								<div style="font-size:14px;line-height:37px;margin-right:10px;color: #606266;font-weight: bold;width: 65px;text-align: right;">
									添加时间:
								</div>
								<el-date-picker v-model="timeValue1" type="daterange" range-separator="~"
									start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
							</div>
						</div>
						<div style="width:150px">
							<el-link type="info" style="color: #666;" href="https://jusnn6k8al.feishu.cn/docs/doccnwkiTFfUXvvCzR6dgprgekL"
							target="_blank">
							<i class="el-icon-document" style="margin-right: 3px;"></i>企微客户管理说明
							</el-link>
						</div>
						<!-- <el-button type="text" style="color:black;margin-top:-20px" icon="el-icon-document">
							<a href="https://jusnn6k8al.feishu.cn/docs/doccnwkiTFfUXvvCzR6dgprgekL"
								target="_blank">企微客户管理说明</a>
						</el-button> -->
					</div>
					<div style="display:flex;flex-direction:row;flex-wrap:wrap">
						<div class="FlexRow" style="margin-bottom:10px">
							<div style="font-size:14px;line-height:37px;color: #606266;font-weight: bold;width: 65px;text-align: right;">好友关系:</div>
							<div style="margin:0px 10px;height:38px">
								<el-select v-model="friends" placeholder="请选择员工" multiple filterable collapse-tags
									style="width:300px">
									<el-option v-for="(v,i) in friendList" :key="i" :value="v.Id" :label="v.EmployeeName">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="FlexRow" style="margin-bottom:10px">
							<div style="font-size:14px;line-height:37px;margin:0px 10px;color: #606266;font-weight: bold;width: 65px;text-align: right;">客户标签:</div>
							<el-select v-model="theTag" ref="theTagSel" multiple filterable collapse-tags
								placeholder="请选择标签" style="width:300px">
								<el-option v-for="(v,i) in theTagList" :key="i" :value="v.TagDetailId" :label="v.Name">
								</el-option>
							</el-select>
						</div>
						<div class="FlexRow" style="margin-bottom:10px">
							<el-button type="primary" style="height:36px;margin-left: 15px;" @click="query1">查询</el-button>
							<el-button type="primary" style="height:36px;margin-left: 15px;" @click="reset">重置</el-button>
						</div>
					</div>
				</div>
				<div style="margin-top:15px" v-show="activeIndex == '2'">
					<div class="FlexRow" style="justify-content: space-between;">
						<div class="FlexRow" style="flex-wrap:wrap">
							<div class="FlexRow" style="margin-bottom:10px">
								<div style="font-size:14px;line-height:37px;color: #606266;font-weight: bold;">关键字:</div>
								<el-select v-model="selValue" placeholder="" style="margin:0px 10px;width:120px"
									@change="selChange">
									<el-option v-for="(v, i) in selOption" :key="i" :value="v.value" :label="v.label">
									</el-option>
								</el-select>
								<el-input v-model="keywords1" :placeholder="placeholder" style="width:250px"></el-input>
							</div>
							<div class="FlexRow" style="margin-bottom:10px">
								<div style="font-size:14px;line-height:37px;margin:0px 10px;color: #606266;font-weight: bold;">
									所在群聊:
								</div>
								<el-select v-model="groutChat" placeholder="请选择群聊" multiple filterable collapse-tags
									style="width:300px">
									<el-option v-for="(v,i) in groutChatList" :key="i" :value="v.Id" :label="v.GroupName">
									</el-option>
								</el-select>
							</div>
							<el-button type="primary" style="margin:0px 10px;height:36px" @click="query2">查询</el-button>
						</div>
						<div style="width:160px;white-space: nowrap;">
							<el-link type="info" style="color: #666;" href="https://jusnn6k8al.feishu.cn/docs/doccnwkiTFfUXvvCzR6dgprgekL"
							target="_blank">
							<i class="el-icon-document" style="margin-right: 3px;"></i>企微客户管理说明
							</el-link>
						</div>
						<!-- <el-button type="text" style="color:black;margin-top:-20px" icon="el-icon-document">
							<a href="https://jusnn6k8al.feishu.cn/docs/doccnwkiTFfUXvvCzR6dgprgekL"
								target="_blank">企微客户管理说明</a>
						</el-button> -->
					</div>
				</div>
			</el-card>
			<!-- 全部客户表格 -->
			<el-card style="margin-top:10px" v-show="activeIndex == '1'" shadow="never">
				<div class="FlexRow" style="align-items:center">
					<div style="margin-left:10px">
						<el-checkbox v-model="repeat" @change="changeRepeat(1)">查看重复添加好友的客户</el-checkbox>
					</div>
					<div>
						<buttonPermissions :datas="'qyCustomerManagesynchronous'">
							<div class="FlexRow">
								<span style="color:#606266;font-size:14px">丨</span>
								<el-popover placement="top" v-model="synchronousvisible1">
									<div v-loading="synchronousLoading1">
										<p>每24小时仅可同步1次企微客户数据，是否确认继续同步？</p>
										<div style="text-align: right; margin: 0">
											<el-button size="mini" @click="synchronousvisible1 = false">取消</el-button>
											<el-button type="primary" size="mini" @click="confirmsynchronous(1)">确定</el-button>
										</div>
									</div>
									<el-button slot="reference" type="text" style="color:#606266;margin:0px;padding:0px" icon="el-icon-refresh">同步客户数据</el-button>
								</el-popover>
							</div>
						</buttonPermissions>
					</div>
				</div>
				<div style="margin-top:10px">
					<el-table :data="dataList1" ref="table1" @selection-change="selectionChange1"
						v-loading="tableloading1">
						<el-table-column type="selection" width="45px" :key="1"></el-table-column>
						<el-table-column prop="" label="客户" width="300px" :key="2">
							<template slot-scope="scope">
								<div class="FlexRow">
									<div style="width:50px;height:50px">
										<img :src="scope.row.WxHeadUrl?scope.row.WxHeadUrl:defaultImg"
											style="width:100%;height:100%;border-radius: 3px;" alt="" />
									</div>
									<div style="margin-left:10px">
										<div style="width:150px" class="theFontHidden">{{scope.row.WxNickname}}</div>
										<div style="width:150px" class="theFontHidden FlexRow flex-align-center">{{scope.row.Phone}}
											<eyes-hide v-if="scope.row.Phone" v-model="scope.row.Phone" :data="{MemberId:scope.row.Id}"></eyes-hide>
										</div>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="" label="标签" width="300px" :key="3">
							<template slot-scope="scope">
								<el-popover :disabled="false" placement="right" trigger="hover" width="400"
									v-if="scope.row.TagList!=undefined&&scope.row.TagList.length>0">
									<span style="display: inline-block;max-height: 400px;overflow: auto;">
										<el-tag style="margin-right:5px;margin-bottom: 5px;" type="info" v-for="(v,i) in scope.row.TagList"
											:key="i">{{v.Name}}</el-tag>
									</span>
									<span style="flex-wrap: wrap;" calss="FlexRow" slot="reference">
										<el-tag style="margin:0px 5px 5px 0px" type="info"
											v-for="(v,i) in scope.row.TagList" :key="i" v-if="i<2">{{v.Name}}</el-tag>
										<el-tag type="info"
											v-if="scope.row.TagList!=undefined && scope.row.TagList.length > 2">...
										</el-tag>
									</span>
								</el-popover>
								<div v-else>--</div>
							</template>
						</el-table-column>
						<el-table-column prop="" label="好友关系" width="300px" :key="4">
							<template slot-scope="scope">
								<el-popover :disabled="false" trigger="hover" placement="right" width="400">
									<span>
										<div style="font-size:14px;font-weight:bold">好友关系:</div>
										<div style="max-height:150px;overflow:hidden;overflow-y:auto">
											<div v-for="(v,i) in scope.row.FriendsList" :key="i">
												{{v.AddTime}}&nbsp;&nbsp;添加员工&nbsp;&nbsp;&nbsp;&nbsp;{{v.EmployeeName}}
											</div>
										</div>
									</span>
									<div calss="FlexRow" style="flex-wrap:nowrap;display: inline-flex;" slot="reference">
										<el-tag style="margin:0px 5px 5px 0px" v-for="(v,i) in scope.row.FriendsList"
											:key="i" v-if="i<2">
											<i class="el-icon-user"></i>{{v.EmployeeName}}
										</el-tag>
										<el-tag
											v-if="scope.row.FriendsList!=undefined && scope.row.FriendsList.length > 2">
											...</el-tag>
									</div>
								</el-popover>
							</template>
						</el-table-column>
						<el-table-column prop="QyWeixinFirstAddFriendsTime" label="添加时间" :key="5">
							<template slot="header" slot-scope="scope">
								<div class="FlexRow">
									<div>添加时间</div>
									<el-tooltip class="item" effect="dark" content="首次成为企业微信客户的时间" placement="top">
										<i class="el-icon-warning-outline"
											style="font-size:18px;margin:2px 0px 0px 5px"></i>
									</el-tooltip>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="CustomerRecordList" label="客户动态" :key="6">
							<template slot-scope="scope">
								<div style="display:flex;flex-direction:row">
									<div v-if="scope.row.CustomerRecordList!=undefined&&scope.row.CustomerRecordList.length>0"
										style="width:130px;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;overflow: hidden;">
										<span v-for="(v,i) in scope.row.CustomerRecordList" :key="i">
											{{v.RecordTypeValue}}:{{v.Remark}};
										</span>
									</div>
									<div style="color:rgb(70,164,255);cursor:pointer;display:flex;flex-direction:row;align-items:flex-end"
										@click="todetailmore(scope.row)"
										v-if="scope.row.CustomerRecordList!=undefined&&scope.row.CustomerRecordList.length>0">
										更多</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="" label="操作" :key="7">
							<template slot-scope="scope">
								<el-button type="text" @click="todetail(scope.row)">详情</el-button>
								<el-button type="text" @click="tagging(scope.row)">设置标签</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div style="margin-top:20px;display:flex;flex-direction:row;justify-content: space-between;">
					<div style="display:flex;flex-direction:row;">
						<el-checkbox style="margin-left:13px" v-model="thisPageAll1" @change="pageCheck1">当前页全选
						</el-checkbox>
						<div>
							<el-button type="primary" size="mini" plain style="margin:-10px 0px 0px 15px;"
								@click="batchTag1">批量打标签</el-button>
						</div>
						<div>
							<buttonPermissions :datas="'qyCustomerManageBatchRemoveTag'">
								<el-button type="danger" size="mini" plain style="margin:-10px 0px 0px 15px;"
								@click="handleClearTag">批量去除标签</el-button>
							</buttonPermissions>
						</div>
					</div>
					<div style="text-align:right">
						<el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
							:current-page="currentPage1" :page-sizes="[10, 20, 30, 40,50]" :page-size="sizepage1"
							layout="total, sizes, prev, pager, next, jumper" :total="total1">
						</el-pagination>
					</div>
				</div>
			</el-card>
			<!-- 群聊表格 -->
			<el-card style="margin-top:10px" v-show="activeIndex == '2'" shadow="never">
				<div class="FlexRow" style="align-items:center">
					<div style="margin-left:10px">
						<el-checkbox v-model="repeat" @change="changeRepeat(2)">查看重复入群的客户</el-checkbox>
					</div>
					<div>
						<buttonPermissions :datas="'qyCustomerManagesynchronous'">
							<div class="FlexRow">
								<span style="color:#606266;font-size:14px">丨</span>
								<el-popover placement="top" v-model="synchronousvisible2">
									<div v-loading="synchronousLoading2">
										<p>每24小时仅可同步1次企微客户数据，是否确认继续同步？</p>
										<div style="text-align: right; margin: 0">
											<el-button size="mini" @click="synchronousvisible2 = false">取消</el-button>
											<el-button type="primary" size="mini" @click="confirmsynchronous(2)">确定</el-button>
										</div>
									</div>
									<el-button slot="reference" type="text" style="color:#606266;margin:0px;padding:0px" icon="el-icon-refresh">同步客户数据</el-button>
								</el-popover>
							</div>
						</buttonPermissions>
					</div>
				</div>
				<div style="margin-top:10px">
					<el-table :data="groupList" ref="table2" @selection-change="selectionChange2"
						v-loading="tableloading2">
						<el-table-column type="selection" width="45px" :key="8"></el-table-column>
						<el-table-column prop="" label="客户" width="230px" :key="9">
							<template slot-scope="scope">
								<div class="FlexRow">
									<div style="width:50px;height:50px">
										<img :src="scope.row.WxHeadUrl?scope.row.WxHeadUrl : defaultImg"
											style="width:100%;height:100%;border-radius: 3px;" alt="" />
									</div>
									<div style="margin-left:10px">
										<div style="width:150px" class="theFontHidden">{{scope.row.WxNickname}}</div>
										<div style="width:150px" class="theFontHidden">{{scope.row.Phone}}</div>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="QyWeixinGroupList" label="所在群聊" width="200px" :key="10">
							<template slot-scope="scope">
								<div
									style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp:2;overflow: hidden;">
									<p
										v-if="scope.row.QyWeixinGroupList!=undefined &&scope.row.QyWeixinGroupList.length>0">
										<span v-for="(v,i) in scope.row.QyWeixinGroupList"
											:key="i">【{{v.GroupName}}】&nbsp;&nbsp;</span>
									</p>
									<p v-else>--</p>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="QyWeixinFirstJoinGroupTime" label="入群时间" :key="11">
							<template slot="header" slot-scope="scope">
								<div class="FlexRow">
									<div>入群时间</div>
									<el-tooltip class="item" effect="dark" content="首次加入企业微信客户群的时间" placement="top">
										<i class="el-icon-warning-outline"
											style="font-size:18px;margin:2px 0px 0px 5px"></i>
									</el-tooltip>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="" label="好友关系" width="300px" :key="12">
							<template slot-scope="scope">
								<el-popover :disabled="false" trigger="hover" placement="right" width="400">
									<div>
										<div style="font-size:14px;font-weight:bold">好友关系:</div>
										<div style="max-height:150px;overflow:hidden;overflow-y:auto">
											<div v-for="(v,i) in scope.row.FriendsList" :key="i">
												{{v.AddTime}}&nbsp;&nbsp;添加员工&nbsp;&nbsp;&nbsp;&nbsp;{{v.EmployeeName}}
											</div>
										</div>
									</div>
									<span calss="FlexRow" style="flex-wrap:nowrap" slot="reference">
										<el-tag style="margin:0px 5px 5px 0px" v-for="(v,i) in scope.row.FriendsList"
											:key="i" v-if="i<2">
											<i class="el-icon-user"></i>{{v.EmployeeName}}
										</el-tag>
										<el-tag
											v-if="scope.row.FriendsList!=undefined && scope.row.FriendsList.length > 2">
											...</el-tag>
									</span>
								</el-popover>
							</template>
						</el-table-column>
						<el-table-column prop="" label="标签" :key="13" width="300px">
							<template slot-scope="scope">
								<el-popover :disabled="false" trigger="hover" placement="right" width="400"
									v-if="scope.row.TagList!=undefined&&scope.row.TagList.length>0">
									<div style="display: inline-block;max-height: 400px;overflow: auto;">	
										<el-tag style="margin:0px 5px 5px 0px" v-for="(v,i) in scope.row.TagList" :key="i"
											type="info">{{v.Name}}</el-tag>
									</div>
									<span calss="FlexRow" slot="reference">
										<el-tag style="margin:0px 5px 5px 0px" v-for="(v,i) in scope.row.TagList"
											:key="i" type="info" v-if="i<2">{{v.Name}}</el-tag>
										<!-- <el-tag v-if="scope.row.tagList!=undefined && scope.row.TagList.length > 2" type="info">...</el-tag> -->
										<el-tag type="info"
											v-if="scope.row.TagList!=undefined && scope.row.TagList.length > 2">...
										</el-tag>
									</span>
								</el-popover>
								<span v-else>--</span>
							</template>
						</el-table-column>
						<el-table-column prop="" label="客户动态" :key="14">
							<template slot-scope="scope">
								<div style="display:flex;flex-direction:row">
									<div v-if="scope.row.CustomerRecordList!=undefined&&scope.row.CustomerRecordList.length>0"
										style="width:130px;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp: 2;overflow: hidden;">
										<span v-for="(v,i) in scope.row.CustomerRecordList" :key="i">
											{{v.RecordTypeValue}}:{{v.Remark}};
										</span>
									</div>
									<div style="color:rgb(70,164,255);cursor:pointer;display:flex;flex-direction:row;align-items:flex-end"
										@click="todetailmore(scope.row)"
										v-if="scope.row.CustomerRecordList!=undefined&&scope.row.CustomerRecordList.length>0">
										更多</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="" label="操作" :key="15">
							<template slot-scope="scope">
								<el-button type="text" @click="todetail(scope.row)">详情</el-button>
								<el-button type="text" @click="tagging(scope.row)">设置标签</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div style="margin-top:20px;display:flex;flex-direction:row;justify-content: space-between;">
					<div style="display:flex;flex-direction:row;">
						<el-checkbox style="margin-left:13px" v-model="thisPageAll2" @change="pageCheck2">当前页全选
						</el-checkbox>
						<div>
							<el-button type="primary" size="mini" plain style="margin:-10px 0px 0px 15px;"
							@click="batchTag2">批量打标签</el-button>
						</div>
						<div>
							<buttonPermissions :datas="'qyCustomerManageBatchRemoveTag'">
								<el-button type="danger" size="mini" plain style="margin:-10px 0px 0px 15px;"
								@click="handleClearTag">批量去除标签</el-button>
							</buttonPermissions>
						</div>
					</div>
					<div style="text-align:right">
						<!-- <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
							:current-page="currentPage2" :page-sizes="[10, 20, 30, 40,50]" :page-size="sizepage2"
							layout="total, sizes, prev, pager, next, jumper" :total="total2">
						</el-pagination> -->
					</div>
				</div>
			</el-card>

			<tagging :dialogTag="dialogTag" :title="tagTitle" :selected="selected" @close="calosetagging"
				@confirm="confirm" :showAddBtn="isClearTag?false:true"></tagging>

		</div>
	</div>
</template>

<script>
	import config from "@/config/index";
	import {
		customerstatis,
		employeelistbystate,
		qyweixincustomermanagerpagelist,
		qyweixincustomermanagerbygrouppagelist,
		qyweixinrealgrouppagelist,
		groupList,
		tagList,
		tagadd,
		groupadd,
		tagMemberBatchRemove
	} from "@/api/TurnTomySelf"
	import pushtaglist from "./components/pushTagList"
	import tagging from "./components/tagging"
	import eyesHide from "@/components/eyesHide"
	import {
		employeelist
	} from "@/api/api"
	import {
		tagMemberbatchSet,
		tagMemberlist,
		tagMembermodify,
		qyweixinsuiteauthinfo,
		qyweixincustomersyncqyweixincustomerrelation
	} from "@/api/sv1.0.0";
	
	import buttonPermissions from "@/components/buttonPermissions";
	
	export default {
		components: {
			pushtaglist,
			tagging,
			buttonPermissions,
			eyesHide
		},
		data() {
			var IsEmptyList = (rule, value, callback) => {
				if (value == '') {
					callback(new Error("请输入标签组名称"))
				} else {
					callback()
				}
			};
			var IsEmpty = (rule, value, callback) => {
				if (value == '') {
					callback(new Error("请输入标签名称"))
				} else {
					callback()
				}
			};
			var limitCharacter = (rule, value, callback) => {
				let len = 0
				for (let i = 0; i < value.length; i++) {
					var a = value.charAt(i);
					if (a.match(/[^\x00-\xff]/ig) != null) {
						//汉字占两个字符
						len += 2;
					} else {
						//其他占一个字符
						len += 1;
					}
				}
				if (len > 30) {
					callback(new Error('最长可输入30个字符'))
				} else {
					callback()
				}

			};
			return {
				goUrls: config.GO_URL,
				imgApi: config.IMG_BASE,
				groupImg: 'https://cdn.dkycn.cn/images/melyshop/grouplist.png',
				// defaultImg:"https://cdn.dkycn.cn/images/melyshop/employeeManageDefaultAvatar.png",
				defaultImg: config.DEFAULT_HEADER,
				clientLoading: false,
				clientNum1: 0,
				clientNum2: 0,
				clientNum3: 0,
				clientNum4: 0,
				clientNum5: 0,
				activeIndex: "1",
				selValue: 0, //关键字类型
				selOption: [{
						label: "微信昵称",
						value: 0
					},
					{
						label: "手机号",
						value: 1
					},
				],
				keywords1: "", //关键字
				placeholder: "客户微信昵称",
				timeValue1: '',
				friends: [], //好友关系
				friendList: [],
				theTag: [], //客户标签
				theTagList: [],

				repeat: false, //查看重复添加好友客户
				thisPageAll1: false,
				thisPageAll2: false,
				dataList1: [], //全部客户表格
				tableloading1: false,
				currentPage1: 1,
				sizepage1: 20,
				total1: null,
				groupList: [], //群聊客户表格
				tableloading2: false,
				currentPage2: 1,
				sizepage2: 20,
				total2: null,

				groutChat: [], //所在群聊
				groutChatList: [],
				CheckTableList1: [],
				CheckTableList2: [],
				dialogTag: false,
				dialogLoading: false,
				TagNameKeyWords: "",
				calltagList: [], //标签组
				calltagListAll: [], //所有子标签
				tagloading: false,
				NewTagListName: '',
				NewTagName: "",
				callTagPeopleId: 0,
				addTagListshow: false,
				addListruleForm: { //添加标签组
					Name: '',
					TagDetails: [{
						Name: ''
					}, ],
				},
				addListrules: {
					Name: [{
						required: true,
						trigger: 'blur',
						validator: IsEmptyList
					}, {
						required: true,
						trigger: 'change',
						validator: limitCharacter
					}, ],
					TagDetails: [{
						required: true,
						trigger: 'blur',
						validator: IsEmpty
					}, {
						required: true,
						trigger: 'change',
						validator: limitCharacter
					}, ]
				},
				addTagListLoading: false,
				maxlength: 30,
				selected: [],
				isbatch: false,
				tagTitle: '设置标签',
				isauthorization: true, //是否配置了企业微信
				pageloading: false,
				isClearTag: false,

				synchronousvisible1:false,
				synchronousLoading1:false,
				synchronousvisible2:false,
				synchronousLoading2:false,
			};
		},
		created() {
			this.Isqyweixinsuiteauthinfo()
		},
		methods: {
			async confirmsynchronous(num){
				let loading = 'synchronousLoading'+num
				let show = 'synchronousvisible'+num
				try{
					this[loading] = true
					let res = await qyweixincustomersyncqyweixincustomerrelation()
					if(res.IsSuccess){
						this.$message.success('操作成功')
						this[show] = false
					}
				}finally{
					this[loading] = false
				}
			},
			jump() {
				this.$router.push({
					path: '/saleChannel/qyWechat'
				})
				// let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#':'' 
				// let url = this.goUrls + headsUrls + '/saleChannel/qyWechat'
				// window.open(url)
			},
			//查看是否配置企业微信
			async Isqyweixinsuiteauthinfo() {
				this.pageloading = true
				try {
					let result = await qyweixinsuiteauthinfo({})
					if (result.IsSuccess) {
						// console.log(result.Result)
						this.isauthorization = result.Result.IsFinishQyWeixinSuiteStp 
						// this.isauthorization = false
						if (this.isauthorization) {
							this.getcustomerstatis()
							this.getemployeelistbystate()
							this.gettagList()
							this.getqyweixincustomermanagerpagelist()
						}
					}
				} finally {
					this.pageloading = false
				}
			},
			todetailmore(e) {
				let headsUrls = process.env.NODE_ENV == "production" ? "index.html#" : "";
				let url =
					this.goUrls + headsUrls + "/Customer/CustomerDetail?Id=" + e.Id;
				window.open(url);
			},
			//关闭打标签页面 清空选中的标签数组
			calosetagging() {
				this.selected = []
				this.dialogTag = false;
				this.isClearTag = false;
			},
			//批量打标签
			async confirm(e) {
				
				if (this.isSubmit) return;
				this.isSubmit = true;
				
				if (this.isClearTag){
					this.clearTagFn(e);
					return
				}
				
				// console.log(e)
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					background: 'rgba(39, 37, 37, 0.8);'
				});
				try {
					let list = [] //选中的客户Id
					if (this.activeIndex == 1) {
						list = this.CheckTableList1.map((v) => {
							return v.Id
						})
					} else if (this.activeIndex == 2) {
						list = this.CheckTableList2.map((v) => {
							return v.Id
						})
					}
					let tag = await e
					let tagIdList = tag.map((x) => {
						return x.TagDetailId
					})

					// let data = {
					//   MemberIdList:this.isbatch?list:this.callTagPeopleId,
					//   TagDetailIdList:tagIdList
					// }
					let result = ''
					if (this.isbatch) {

						let data = {
							MemberIdList: list,
							TagDetailIdList: tagIdList
						}
						result = await tagMemberbatchSet(data)
					} else {
						let data = {
							MemberId: this.callTagPeopleId,
							TagDetailIdList: tagIdList
						}
						result = await tagMembermodify(data)
					}
					if (result.IsSuccess) {
						// console.log(result)
						this.$message.success('操作成功')
					}
				} finally {
					this.selected = []
					this.dialogTag = false
					loading.close();
					if (this.activeIndex == 1) {
						this.getqyweixincustomermanagerpagelist()
					} else if (this.activeIndex == 2) {
						this.getqyweixincustomermanagerbygrouppagelist()
					}
					
					setTimeout(()=>{
						this.isSubmit = false;
					}, 2000)
				}

			},

			changeKewwords(e) {
				// console.log(e,1)
				this.TagNameKeyWords = e
			},
			changetagloading() {
				this.tagloading = !this.tagloading
			},
			closedialogTag() {
				this.dialogTag = false
				this.TagNameKeyWords = ''
			},
			openaddTagListshow() {
				this.addTagListshow = true
			},
			closetaglist() {
				this.addTagListshow = false
			},
			limit30(e) {
				let len = 0
				let strnum = 0
				this.maxlength = 30 //每次初始化一下输入最大值  不然在输入值满了之后 删除一位就无法再输入
				for (let i = 0; i < e.length; i++) {
					var a = e.charAt(i);
					if (a.match(/[^\x00-\xff]/ig) != null) {
						//汉字占两个字符
						len += 2;
					} else {
						//其他占一个字符
						len += 1;
					}
					if (len < 30) {
						strnum += 1
					} else {
						this.maxlength = strnum
					}
				}
				// console.log(this.maxlength,len)
			},
			//查询标签
			queryTag() {
				this.getgroupList()
			},
			tagClosed() {
				this.TagNameKeyWords = ''
			},

			async gettagMemberlist(e) {
				// console.log(Id)
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					// spinner: 'el-icon-loading',
					background: 'rgba(39, 37, 37, 0.8);'
				});
				let Id = await e.Id
				let data = {
					MemberId: Id
				}
				let result = await tagMemberlist(data)
				if (result.IsSuccess) {
					// console.log(result.Result)
					this.selected = result.Result
					this.dialogTag = true
					// this.getgroupList()
				}
				loading.close();
			},


			tagging(e) {
				this.callTagPeopleId = e.Id
				// console.log(e.Id)
				this.isbatch = false
				this.tagTitle = '设置标签'
				this.gettagMemberlist(e)
			},
			changeRepeat(e) {
				if (e == 1) {
					this.currentPage1 = 1
					this.getqyweixincustomermanagerpagelist()
				} else if (e == 2) {
					this.currentPage2 = 1
					this.getqyweixincustomermanagerbygrouppagelist()
				}
			},
			//查询
			query1() {
				this.currentPage1 = 1
				this.getqyweixincustomermanagerpagelist()
			},
			query2() {
				this.currentPage2 = 1
				this.getqyweixincustomermanagerbygrouppagelist()
			},
			//添加新标签 显示输入框
			showInput(i) {
				// console.log(i)
				this.calltagList[i].inputshow = true
				this.$forceUpdate() //强制更新数据
				this.$nextTick(() => {
					this.$refs["saveTagInput" + i][0].$refs.input.focus();
				});
			},
			//添加新标签
			handleInputConfirm(i) {
				let tagarr = []
				if (this.NewTagName) {
					tagarr.push(this.NewTagName)
					this.totagadd(i, tagarr)
				}
				this.NewTagName = ""
				this.calltagList[i].inputshow = false
				this.$forceUpdate()
			},
			//添加标签
			async totagadd(e, list) {
				this.tagloading = true
				let msg = await e
				let arr = await list
				try {
					let data = {
						TagGroupId: this.calltagList[msg].TagGroupId,
						TagDetails: arr
					}
					let result = await tagadd(data)
					if (result.IsSuccess) {
						//成功则直接添加进去  不调用接口重新获取数据
						let newtag = {
							Name: arr[0],
							check: false
						}
						this.calltagList[msg].children.push(newtag)
						this.$forceUpdate()
					}
				} finally {
					this.NewTagName = "";
					this.calltagList[msg].inputshow = false
					this.tagloading = false
				}
			},
			//选择标签
			checkTag(i, n) {
				// console.log(this.calltagListAll)
				this.calltagList[i].children[n].check = true;
				// console.log(this.calltagList)
			},
			//批量打标签
			batchTag1() {
				if (this.CheckTableList1.length <= 0) {
					this.$message.error("请选择打标签的客户");
				} else {
					this.dialogTag = true;
					this.isbatch = true
					this.tagTitle = '批量打标签'
					// this.getgroupList()
				}
			},
			batchTag2() {
				if (this.CheckTableList2.length <= 0) {
					this.$message.error("请选择打标签的客户");
				} else {
					this.dialogTag = true;
					this.isbatch = true
					this.tagTitle = '批量打标签'
				}
			},
			//表格多选全选
			selectionChange1(sel) {
				// console.log(sel)
				this.CheckTableList1 = sel;
				if (sel.length == this.dataList1.length) {
					this.thisPageAll1 = true;
				} else {
					this.thisPageAll1 = false;
				}
			},
			selectionChange2(sel) {
				// console.log(sel)
				this.CheckTableList2 = sel;
				if (sel.length == this.groupList.length) {
					this.thisPageAll2 = true;
				} else {
					this.thisPageAll2 = false;
				}
			},
			pageCheck2() {
				this.$refs.table2.toggleAllSelection();
			},
			pageCheck1() {
				this.$refs.table1.toggleAllSelection();
			},
			//表格页面页码切换
			handleSizeChange2(e) {
				this.currentPage2 = 1;
				this.sizepage2 = e;
				this.getqyweixincustomermanagerbygrouppagelist()
			},
			handleCurrentChange2(e) {
				this.currentPage2 = e;
				this.getqyweixincustomermanagerbygrouppagelist()
			},
			handleSizeChange1(e) {
				this.currentPage1 = 1;
				this.sizepage1 = e;
				this.getqyweixincustomermanagerpagelist()
			},
			handleCurrentChange1(e) {
				this.currentPage1 = e;
				this.getqyweixincustomermanagerpagelist()
			},
			//重置
			reset() {
				this.selValue = 0;
				this.keywords1 = "";
				this.timeValue1 = '';
				this.friends = [];
				this.theTag = [];

			},
			//切换tab页
			handleSelect(key, keyPath) {
				this.synchronousvisible1 = false
				this.synchronousvisible2 = false
				this.activeIndex = key;
				this.repeat = false
				this.currentPage1 = 1
				this.currentPage2 = 1
				if (key == 1) {
					this.getemployeelistbystate()
					this.gettagList()
					this.getqyweixincustomermanagerpagelist()
				} else if (key == 2) {
					this.getqyweixinrealgrouppagelist()
					this.getqyweixincustomermanagerbygrouppagelist()
				}
				// console.log(this.activeIndex)
			},
			selChange(e) {
				// console.log(e)
				this.placeholder =
					"客户" +
					this.selOption.find((v) => {
						return v.value == e;
					}).label;
			},
			//头部五个数据
			async getcustomerstatis() {
				this.clientLoading = true
				let result = await customerstatis({})
				if (result.IsSuccess) {
					this.clientNum1 = result.Result.AllCustomerCount
					this.clientNum2 = result.Result.RepeatAddFriendsCustomerCount
					this.clientNum3 = result.Result.TodayNewFriendsCount
					this.clientNum4 = result.Result.TodayLossingFriendsCount
					this.clientNum5 = result.Result.TodayNetGrowthFriendsCount
				}
				this.clientLoading = false
			},
			//好友关系
			async getemployeelistbystate() {
				let data = {
					KeyWords: null,
					EmployeeRoleId: null,
					ShopId: null,
					IsCustomerService: null,
					EmployeeState: 1, //在职
					Skip: 0,
					Take: 99999,
				}
				let result = await employeelistbystate(data)
				if (result.IsSuccess) {
					// console.log(result.Result)
					this.friendList = result.Result
				}
			},
			//标签列表
			async gettagList() {
				let data = {
					Skip: 0,
					Take: 99999,
					OrderBy: 'TagDetailId',
					IsAsc: true
				}
				let result = await tagList(data)
				if (result.IsSuccess) {
					let taglist = result.Result.Results
					let data = {
						Name: '无任何标签',
						TagDetailId: 0,
					}
					taglist.unshift(data)
					// console.log(result.Result)
					this.theTagList = taglist
				}
			},
			changedate(time) {
				// console.log(time)
				if (time) {
					let year = time.getFullYear()
					let month = time.getMonth() + 1 >= 10 ? time.getMonth() + 1 : "0" + (time.getMonth() + 1)
					let date = time.getDate() >= 10 ? time.getDate() : "0" + time.getDate()
					return (year + '-' + month + '-' + date)
				}
			},
			//将天数+1
			formatNextDate(dateStr){
				var date = dateStr.split('-');
				var year = date[0];
				var month = +date[1] - 1;
				var day = +date[2];
				
				return this.getTimeStr(new Date(year, month,day+1))
			},
			//将时间转换为字符串类型
			getTimeStr(date) {
				var year = date.getFullYear();
				var month = date.getMonth() + 1;
				var day = date.getDate();
			
				return [
					year,
					month > 9 ? month : ('0' + month),
					day > 9 ? day : ('0' + day),
				].join('-')
			},
			//全部客户表格
			async getqyweixincustomermanagerpagelist() {
				this.tableloading1 = true
				let a = this.theTag.find((v) => {

					return v == 0
				})
				// console.log(a)
				// console.log(this.changedate(this.timeValue1[0]))
				let data = {
					KeywordsType: this.selValue,
					Keywords: this.keywords1,
					StartTime: (this.timeValue1 && this.changedate(this.timeValue1[0])) ? this.changedate(this.timeValue1[0]) : '',
					EndTime: (this.timeValue1 && this.changedate(this.timeValue1[0])) ? this.formatNextDate(this.changedate(this.timeValue1[1])) : '',
					EmployeeIdList: this.friends,
					TagIdList: this.theTag,
					IsHaveTag: a == 0 ? false : true,
					IsRepeatAddFriends: this.repeat,
					Skip: (this.currentPage1 - 1) * this.sizepage1,
					Take: this.sizepage1,
				}
				// console.log(data.IsHaveTag)
				let result = await qyweixincustomermanagerpagelist(data)
				if (result.IsSuccess) {
					// console.log(result.Result.Results.map((v)=>{
					//   return v.CustomerRecordList
					// }))
					// console.log(result.Result.Results)
					this.dataList1 = result.Result.Results
					this.total1 = result.Result.Total
				}
				this.tableloading1 = false
			},
			//通过群  表格
			async getqyweixincustomermanagerbygrouppagelist() {
				this.tableloading2 = true
				let a = this.groutChat.find((v) => {
					return v == 0
				})
				// console.log(a)
				let data = {
					KeywordsType: this.selValue,
					Keywords: this.keywords1,
					IsJoinGroup: a == 0 ? false : true,
					GroupIdList: this.groutChat,
					IsRepeatJoinGroup: this.repeat,
					Skip: (this.currentPage2 - 1) * this.sizepage2,
					Take: this.sizepage2
				}
				// console.log(data)
				let result = await qyweixincustomermanagerbygrouppagelist(data)
				if (result.IsSuccess) {
					// console.log(result.Result)
					// this.currentPage2 = 1
					this.groupList = result.Result.Results
					this.total2 = result.Result.Total
				}
				this.tableloading2 = false
			},
			//所在群聊 
			async getqyweixinrealgrouppagelist() {
				let data = {
					IsDissolution: false,
					IsQyWeixinRealGroupSelect: true,
					Skip: 0,
					Take: 99999,
				}
				let result = await qyweixinrealgrouppagelist(data)
				if (result.IsSuccess) {
					// console.log(result.Result)
					let list = result.Result.Results
					let data = {
						Id: 0,
						GroupName: '未加入任何群聊'
					}
					list.unshift(data)
					// console.log(result.Result.Results)
					this.groutChatList = list
				}
			},
			//查看详情
			todetail(record) {
				let headsUrls = process.env.NODE_ENV == "production" ? "index.html#" : "";
				let url =
					this.goUrls + headsUrls + "/Customer/CustomerDetail?Id=" + record.Id;
				window.open(url);
			},
			//获取标签组
			async getgroupList() {
				this.tagloading = true
				let data = {
					Keywords: this.TagNameKeyWords,
					Skip: 0,
					Take: 99999,
				}
				// console.log(1)
				let result = await groupList(data)
				if (result.IsSuccess) {
					let calltagList = result.Result.Results
					calltagList.map((v) => {
						v.inputshow = false
						v.children = []
						return
					})

					// console.log(this.calltagList)
					this.togettaglist(calltagList)
				}
			},
			
			
			handleClearTag(){
				this.clearList = this.activeIndex == 1 ? this.CheckTableList1 : this.CheckTableList2;
				
				if (!this.clearList.length) {
					this.$message.error("请选择客户");
					return;
				}
				
				this.isClearTag = true;
				this.dialogTag = true;
				this.tagTitle = '批量去除标签'
			},
			async clearTagFn(list){
				try{
					const res = await tagMemberBatchRemove({
						MemberIdList: this.clearList.map(item=>{
							return item.Id
						}),
						TagDetailIdList: list.map(item=>{
							return item.TagDetailId
						})
					})
					
					if (res.IsSuccess){
						this.$message.success('操作成功');
						this.isClearTag = false;
						this.dialogTag = false;
						
						if (this.activeIndex == 1) {
							this.getqyweixincustomermanagerpagelist()
						} else {
							this.getqyweixincustomermanagerbygrouppagelist()
						}
						
					}
				}catch(e){
					//TODO handle the exception
				}finally{
					setTimeout(()=>{
						this.isSubmit = false;
					}, 2000)
				}
			},
		},
	};
</script>

<style scoped lang="less">
	.blue-box {
		border: 1px solid #409eff;
		background: #fbfdff;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}

	.friendClass {
		width: 100%;
		border-bottom: 1px solid rgb(180, 180, 180);
		height: 250px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.FlexRow {
		display: flex;
		flex-direction: row;
	}
	.flex-align-center{
		align-items: center;
	}

	.theFontHidden {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.heightMiddle {
		height: 100%;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}

	.friend-class-leftList {
		width: 100%;
		height: 200px;
		margin-top: 7px;
		overflow: hidden;
		overflow-y: auto;
		padding-right: 10px;
	}

	.friend-class-leftList-label {
		width: 100%;
		height: 50px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.friend-class-leftList-font {
		width: 120px;
		font-weight: 100;
		line-height: 50px;
		float: left;
		margin-left: 5px;
	}

	.tag-box {
		width: 100%;
		position: relative;
		top: -36px;
		left: 0px;
		padding: 0px 30px 0px 15px;
	}

	.batchTagBox {
		margin-top: 20px;
	}

	.batchTagBox:first-child {
		margin-top: 0px;
	}

	::v-deep .el-checkbox-button__inner {
		border-left: 1px solid #DCDFE6
	}

	// ::v-deep .el-select .el-tag{
	//   max-width: 100%;
	// }
	// ::v-deep .el-select .el-tag .el-select__tags-text{
	//   width: 100px !important;
	//   overflow:hidden !important;
	// 	white-space:nowrap;
	// 	text-overflow: ellipsis;
	// }
	// .el-select-dropdown__item {
	// 	width: 300px !important;
	// }
	
	
	.desc-title{
		font-weight: bold;
		margin-top: 30px;
		font-size: 16px;
		margin-bottom: 10px;
	}
	.desc-content{
		font-size: 14px;
		color: #606266;
		line-height: 20px;
		
		>div{
			margin-bottom: 5px;
		}
	}
	
	.content {
		display: flex;
		align-items: center;
		overflow: hidden;
		padding: 15px;
		background-color: #f8f8f9;
		
		
	
		.left {
			flex: 1 1 auto;
			overflow: hidden;
			margin-right: 20px;
			display: flex;
	
			img {
				flex: 0 0 auto;
				width: 46px;
				height: 46px;
			}
	
			.info {
				flex: 1 1 auto;
				overflow: hidden;
				margin-left: 10px;
				padding-top: 2px;
	
				.tit {
					font-size: 14px;
					font-weight: bold;
				}
	
				.tip {
					font-size: 12px;
					color: #999;
					margin-top: 10px;
				}
			}
		}
	
		.right {
			flex: 0 0 auto;
		}
	}
</style>
